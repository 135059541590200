import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import "./contact.scss";

const Contact = () => {
  return (
    <Layout activeMenu="contact">
      <Seo title="Kontakt"></Seo>
      <section className="container">
        <div className="section-title">
          <h2>Kontakt</h2>
        </div>
        <div className="contact-content-wrapper">
          <div className="contact-content">
            <div className="content">
              <div className="company">Jautzke Augenoptik</div>
              <div className="company-desc">
                Meisterbetrieb mit eigener Werkstatt
              </div>
              <div className="adress-opening">
                <div className="address">
                  <div>Rudower Str. 15</div>
                  <div>12351 Berlin</div>
                  <div className="spacer"></div>
                  <div className="table">
                    <div className="key">Telefon</div>
                    <div className="value">
                      <a href="tel:03060257834">030 60257834</a>
                    </div>
                  </div>
                  <div className="table">
                    <div className="key">Fax</div>
                    <div className="value">030 66620424</div>
                  </div>
                  <div className="table">
                    <div className="key">E-Mail</div>
                    <div className="value">
                      <a
                        className="padding-r"
                        href="mailto:info@jautzke-augenoptik.de"
                      >
                        info@jautzke-augenoptik.de
                      </a>
                    </div>
                  </div>
                </div>
                <div className="opening">
                  <div className="table">
                    <div className="key">Montag</div>
                    <div className="value">09:30 - 14:00 Uhr</div>
                  </div>
                  <div className="table">
                    <div className="key">Dienstag</div>
                    <div className="value">09:30 - 18:00 Uhr</div>
                  </div>
                  <div className="table">
                    <div className="key">Mittwoch</div>
                    <div className="value">09:30 - 18:00 Uhr</div>
                  </div>
                  <div className="table">
                    <div className="key">Freitag</div>
                    <div className="value">09:30 - 18:00 Uhr</div>
                  </div>
                  <div className="table">
                    <div className="key">Samstag</div>
                    <div className="value">09:30 - 13:00 Uhr</div>
                  </div>
                  <div className="table">
                    <div className="key">Sonntag</div>
                    <div className="value">geschlossen</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="maps">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2432.0573126622635!2d13.455690715936075!3d52.44187654976812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a8458d04a54e73%3A0x8e7b40e73a93a7e3!2sJautzke%20Augenoptik!5e0!3m2!1sde!2sde!4v1654626744564!5m2!1sde!2sde"
                className="iframe"
                allowFullscreen=""
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps - Jautzke Augenoptik"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
